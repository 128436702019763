<template>

<div id="contenedor">
              <h1>Welcome admin</h1>
              <form action="/php/index.php">
                <label>User:</label>
                <input type="text" name="user" v-model = "user">
                <label>Pass:</label>
                <input type="text" name="pass" v-model = "pass">
                <button @click="login">Login</button>
               </form>
 </div>

</template>

<script>

$.ajax(
  'index.php',
  {
      success: function(data) {
        alert('AJAX call was successful!');
        //alert('Data from the server' + data);
      },
      error: function() {
        alert('There was some error performing the AJAX call!');
      }
   }
);

export default {


}
</script>

<style>

</style>
<template>


    <hr class="visuallyhidden" id="main-content-delimiter">
    <div class="wrapper wrapper--gutter">
        <div class="grid">
            <div class="grid__item landscape--two-thirds">
                <div class="wrapper">
                    <div class="wrapper wrapper--gutter">
                        <div class="grid">
                            <div class="grid__item landscape--two-thirds portable--one-whole">
                                <div class="text-block-intro-page">
                                    <h1 class="h2">Products </h1>
                                    <div>
                                        <p>Efficiency and high standards are the key to your success and ours. At DB Schenker, we have the expertise to manage your logistics and transport needs, all from one place. Whatever the size and nature of your business, and however complex your operations, we’ll take care of your logistics by road, rail, air and ocean.<br></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="section has-margin has-tabs">
        <div class="wrapper block-teaser-full-overlay__wrapper">
            <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--first" data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 25, 2021, 1:11:59 PM">
                <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                    <b>Land Transport</b>
                    <span class="dbs-accordion-item__arrow">
                        <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                            <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                            <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                        </svg>
                    </span>
                </button>
                <div class="dbs-accordion-item__content">
                    <div class="block-teaser--full-overlay
                        block-teaser--row-reverse
                        ">
                        <picture class="block-teaser__media">
                            <source media="(max-width: 394px)" srcset="https://dbschenker.com/resource/image/168/16:9/0/200/b68f9eaf1a8b3ecd73e75ae5d9c4b7b9/hE/lt-product-category-image--global-en-.jpg">
                            <source media="(max-width: 507px)" srcset="https://dbschenker.com/resource/image/168/21:9/0/200/e191c8e8b0a059574c555029d4c3577a/cY/lt-product-category-image--global-en-.jpg">
                            <source media="(max-width: 590px)" srcset="https://dbschenker.com/resource/image/168/11:4/0/200/d366f9ca716fede69fe2d425c7db3495/Qi/lt-product-category-image--global-en-.jpg">
                            <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/168/4:1/0/200/6617f9f3f7b0a72cdbcc84c2453bfb21/Hb/lt-product-category-image--global-en-.jpg">
                            <source media="(max-width: 934px)" srcset="https://dbschenker.com/resource/image/168/21:9/0/400/e191c8e8b0a059574c555029d4c3577a/CC/lt-product-category-image--global-en-.jpg">
                            <source media="(max-width: 1023px)" srcset="https://dbschenker.com/resource/image/168/11:4/0/400/d366f9ca716fede69fe2d425c7db3495/lQ/lt-product-category-image--global-en-.jpg">
                            <source media="(max-width: 1050px)" srcset="https://dbschenker.com/resource/image/168/4:3/0/525/1efd727c1fc7736abad13bdeaf3b4bab/sV/lt-product-category-image--global-en-.jpg">
                            <source media="(min-width: 1051px)" srcset="https://dbschenker.com/resource/image/168/16:9/0/525/b68f9eaf1a8b3ecd73e75ae5d9c4b7b9/oi/lt-product-category-image--global-en-.jpg">
                            <img src="" alt="Land transport border crossing DB Schenker trucks" class="">
                        </picture>
                        <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right">
                            <h2 class="h3 block-teaser__title">Land Transport</h2>
                            <div class="grid grid--wide">
                                <p class="grid__item portable--one-half" data-truncate-lines="8" style="">DB Schenker’s land transport products and services cover an extensive network. Our freight solutions get your shipments where they need to be on time.</p>
                                <div class="grid__item portable--one-half">
                                    <ul class="list ">
                                        <li class="list__item">
                                            <a href="/global/products/land-transport/parcel-delivery" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Parcel</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/land-transport/system-freight" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span> System Freight</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/land-transport/part-and-full-loads" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Part &amp; Full Loads (FTL)</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/land-transport/full-load-solutions" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Full Load Solutions</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item" data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 25, 2021, 1:11:59 PM">
                <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                    <b>Air Freight</b>
                    <span class="dbs-accordion-item__arrow">
                        <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                            <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                            <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                        </svg>
                    </span>
                </button>
                <div class="dbs-accordion-item__content">
                    <div class="block-teaser--full-overlay
                        block-teaser--row
                        ">
                        <picture class="block-teaser__media">
                            <source media="(max-width: 394px)" srcset="https://dbschenker.com/resource/image/2228/16:9/0/200/d451dee9bbffd8c60e1a0b6bfc0b9644/wi/air-product-stage-image--global-en-.jpg">
                            <source media="(max-width: 507px)" srcset="https://dbschenker.com/resource/image/2228/21:9/0/200/a4d92ea219064eca4994e7203b5545a5/hs/air-product-stage-image--global-en-.jpg">
                            <source media="(max-width: 590px)" srcset="https://dbschenker.com/resource/image/2228/11:4/0/200/e1f9399073f336fe3568a8cb25f72341/Sm/air-product-stage-image--global-en-.jpg">
                            <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/2228/4:1/0/200/ee895e3020fd0fa27051f755d0f9ce43/lZ/air-product-stage-image--global-en-.jpg">
                            <source media="(max-width: 934px)" srcset="https://dbschenker.com/resource/image/2228/21:9/0/400/a4d92ea219064eca4994e7203b5545a5/lO/air-product-stage-image--global-en-.jpg">
                            <source media="(max-width: 1023px)" srcset="https://dbschenker.com/resource/image/2228/11:4/0/400/e1f9399073f336fe3568a8cb25f72341/RM/air-product-stage-image--global-en-.jpg">
                            <source media="(max-width: 1050px)" srcset="https://dbschenker.com/resource/image/2228/4:3/0/525/8923571c3ae10ccf9aee02f2e4df983b/Xd/air-product-stage-image--global-en-.jpg">
                            <source media="(min-width: 1051px)" srcset="https://dbschenker.com/resource/image/2228/16:9/0/525/d451dee9bbffd8c60e1a0b6bfc0b9644/tI/air-product-stage-image--global-en-.jpg">
                            <img src="" alt="DB Schenker Employee Air Freight Product" class="">
                        </picture>
                        <div class="block-teaser__text theme-light block-teaser__text--with-links align-left">
                            <h2 class="h3 block-teaser__title">Air Freight</h2>
                            <div class="grid grid--wide">
                                <p class="grid__item portable--one-half" data-truncate-lines="8" style="">With a global network and advanced logistics solutions, our air freight forwarding products provide comprehensive solutions to your air cargo needs. We help transport your cargo anywhere in the world, making your business run smoothly no matter where your goods need to be transported.</p>
                                <div class="grid__item portable--one-half">
                                    <ul class="list ">
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/general-products" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>General Air Freight Products</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/charter-services" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Charter Services</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/intermodal-solutions" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Intermodal Solutions</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/flight-operations" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Flight Operations</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/intercontinental-supply-chain-solutions" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Intercontinental Supply Chain Solutions (ISC)</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/jetxpress" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Express Products</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/global-flight-network" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Global Flight Network</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/sustainability-in-air-freight" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Sustainable Air Freight: FRA-PVG-FRA</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/cargo-iq" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Quality Management System (Cargo iQ)</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/air-freight/airtrack" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>AirTrack</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item" data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 25, 2021, 1:11:59 PM">
                <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                    <b>Ocean Freight</b>
                    <span class="dbs-accordion-item__arrow">
                        <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                            <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                            <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                        </svg>
                    </span>
                </button>
                <div class="dbs-accordion-item__content">
                    <div class="block-teaser--full-overlay
                        block-teaser--row-reverse
                        ">
                        <picture class="block-teaser__media">
                            <source media="(max-width: 394px)" srcset="https://dbschenker.com/resource/image/639422/16:9/0/200/71cce53ba664eea3dcf4b5746fb5ddb7/Gt/ocean-freight-category-focus-left--global-en-.jpg">
                            <source media="(max-width: 507px)" srcset="https://dbschenker.com/resource/image/639422/21:9/0/200/6b95f639643c2308aecf7c07edaa58a3/jJ/ocean-freight-category-focus-left--global-en-.jpg">
                            <source media="(max-width: 590px)" srcset="https://dbschenker.com/resource/image/639422/11:4/0/200/12342e277d8525056a24f7dcf3b0b7d0/lL/ocean-freight-category-focus-left--global-en-.jpg">
                            <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/639422/4:1/0/200/e7701f8eec7b7907386a19270c70d393/Zk/ocean-freight-category-focus-left--global-en-.jpg">
                            <source media="(max-width: 934px)" srcset="https://dbschenker.com/resource/image/639422/21:9/0/400/6b95f639643c2308aecf7c07edaa58a3/Nz/ocean-freight-category-focus-left--global-en-.jpg">
                            <source media="(max-width: 1023px)" srcset="https://dbschenker.com/resource/image/639422/11:4/0/400/12342e277d8525056a24f7dcf3b0b7d0/ep/ocean-freight-category-focus-left--global-en-.jpg">
                            <source media="(max-width: 1050px)" srcset="https://dbschenker.com/resource/image/639422/4:3/0/525/c28ef8f613192ea71df4c760ed6c34be/BX/ocean-freight-category-focus-left--global-en-.jpg">
                            <source media="(min-width: 1051px)" srcset="https://dbschenker.com/resource/image/639422/16:9/0/525/71cce53ba664eea3dcf4b5746fb5ddb7/of/ocean-freight-category-focus-left--global-en-.jpg">
                            <img src="" alt="Ocean Freight Container Ship" class="">
                        </picture>
                        <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right">
                            <h2 class="h3 block-teaser__title">Ocean Freight</h2>
                            <div class="grid grid--wide">
                                <p class="grid__item portable--one-half" data-truncate-lines="8" style=""> DB Schenker’s ocean freight ships more than 5,500 containers a day to ports all around the world, making us a top forwarder for your ocean freight shipments. </p>
                                <div class="grid__item portable--one-half">
                                    <ul class="list ">
                                        <li class="list__item">
                                            <a href="/global/products/ocean-freight/lcl-deliveries" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Less-than-container Load (LCL)</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/ocean-freight/fcl-deliveries" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Full Container Load (FCL)</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/ocean-freight/reefer-management" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Reefer Management</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/ocean-freight/intermodal-solutions" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Intermodal Solutions</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/ocean-freight/intercontinental-supply-chain-solutions" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Intercontinental Supply Chain Solutions (ISC)</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item" data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 25, 2021, 1:11:59 PM">
                <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                    <b>Contract Logistics</b>
                    <span class="dbs-accordion-item__arrow">
                        <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                            <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                            <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                        </svg>
                    </span>
                </button>
                <div class="dbs-accordion-item__content">
                    <div class="block-teaser--full-overlay
                        block-teaser--row
                        ">
                        <picture class="block-teaser__media">
                            <source media="(max-width: 394px)" srcset="https://dbschenker.com/resource/image/2292/16:9/0/200/85d62609c52d703ecb2c8a95930287c3/mH/cl-products-category-image--global-en-.jpg">
                            <source media="(max-width: 507px)" srcset="https://dbschenker.com/resource/image/2292/21:9/0/200/6873cb668036094a8379249819a36851/TS/cl-products-category-image--global-en-.jpg">
                            <source media="(max-width: 590px)" srcset="https://dbschenker.com/resource/image/2292/11:4/0/200/31e5df96d423eee567a4630a9d475ba7/uf/cl-products-category-image--global-en-.jpg">
                            <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/2292/4:1/0/200/772b7076c8fe3badcfc504cf572c4d26/zU/cl-products-category-image--global-en-.jpg">
                            <source media="(max-width: 934px)" srcset="https://dbschenker.com/resource/image/2292/21:9/0/400/6873cb668036094a8379249819a36851/ps/cl-products-category-image--global-en-.jpg">
                            <source media="(max-width: 1023px)" srcset="https://dbschenker.com/resource/image/2292/11:4/0/400/31e5df96d423eee567a4630a9d475ba7/OR/cl-products-category-image--global-en-.jpg">
                            <source media="(max-width: 1050px)" srcset="https://dbschenker.com/resource/image/2292/4:3/0/525/71184bccdaf37231d2f1dc7db05826c8/Pk/cl-products-category-image--global-en-.jpg">
                            <source media="(min-width: 1051px)" srcset="https://dbschenker.com/resource/image/2292/16:9/0/525/85d62609c52d703ecb2c8a95930287c3/lF/cl-products-category-image--global-en-.jpg">
                            <img src="" alt="Contract Logistics Warehouse" class="">
                        </picture>
                        <div class="block-teaser__text theme-light block-teaser__text--with-links align-left">
                            <h2 class="h3 block-teaser__title">Contract Logistics</h2>
                            <div class="grid grid--wide">
                                <p class="grid__item portable--one-half" data-truncate-lines="8" style=""> DB Schenker is one of the leading and fastest growing global logistics service providers. With our innovative industry solutions and worldwide consistent first class operations, we are and we will be the supply chain partner of choice for the best companies globally. </p>
                                <div class="grid__item portable--one-half">
                                    <ul class="list ">
                                        <li class="list__item">
                                            <a href="/global/products/contract-logistics/fullfillment" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Fulfillment Logistics</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/contract-logistics/service-aftermarket" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Reverse Logistics and Aftermarket</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/contract-logistics/ecommerce" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>e-commerce Logistics Solutions</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/contract-logistics/production-logistics" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Production Logistics</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/contract-logistics/supply-chain-solutions" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Value Added Services</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/contract-logistics/short-term-warehousing" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Short-term Warehousing</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item" data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 25, 2021, 1:11:59 PM">
                <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                    <b>Lead Logistics</b>
                    <span class="dbs-accordion-item__arrow">
                        <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                            <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                            <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                        </svg>
                    </span>
                </button>
                <div class="dbs-accordion-item__content">
                    <div class="block-teaser--full-overlay
                        block-teaser--row-reverse
                        ">
                        <picture class="block-teaser__media">
                            <source media="(max-width: 394px)" srcset="https://dbschenker.com/resource/image/639414/16:9/0/200/b5f6e684063ed2404be97cee2578e782/zf/ll-products-category-img--global-en-.jpg">
                            <source media="(max-width: 507px)" srcset="https://dbschenker.com/resource/image/639414/21:9/0/200/b4c9261867cbc7aceaed66474b6bfe47/oe/ll-products-category-img--global-en-.jpg">
                            <source media="(max-width: 590px)" srcset="https://dbschenker.com/resource/image/639414/11:4/0/200/ec42091c020e8fd705733df7af9eb890/Qx/ll-products-category-img--global-en-.jpg">
                            <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/639414/4:1/0/200/ef0fdec4854c56d9a8aa6ce52b179bfa/Or/ll-products-category-img--global-en-.jpg">
                            <source media="(max-width: 934px)" srcset="https://dbschenker.com/resource/image/639414/21:9/0/400/b4c9261867cbc7aceaed66474b6bfe47/Fs/ll-products-category-img--global-en-.jpg">
                            <source media="(max-width: 1023px)" srcset="https://dbschenker.com/resource/image/639414/11:4/0/400/ec42091c020e8fd705733df7af9eb890/dP/ll-products-category-img--global-en-.jpg">
                            <source media="(max-width: 1050px)" srcset="https://dbschenker.com/resource/image/639414/4:3/0/525/e25b233f449d0bfd559c1d0d8029530f/gD/ll-products-category-img--global-en-.jpg">
                            <source media="(min-width: 1051px)" srcset="https://dbschenker.com/resource/image/639414/16:9/0/525/b5f6e684063ed2404be97cee2578e782/Rh/ll-products-category-img--global-en-.jpg">
                            <img src="" alt="Lead Logistics Container Crane" class="">
                        </picture>
                        <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right">
                            <h2 class="h3 block-teaser__title">Lead Logistics</h2>
                            <div class="grid grid--wide">
                                <p class="grid__item portable--one-half" data-truncate-lines="8" style=""> DB Schenker's Lead Logistics products mean your supply chain is optimized with the best service providers from start to finish. </p>
                                <div class="grid__item portable--one-half">
                                    <ul class="list ">
                                        <li class="list__item">
                                            <a href="/global/products/lead-logistics/logistics-services" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Lead Logistics Services</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/lead-logistics/americas" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Lead Logistics Americas</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/lead-logistics/asia-pacific" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Lead Logistics Asia-Pacific</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/lead-logistics/europe" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Lead Logistics Europe</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--last" data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 25, 2021, 1:11:59 PM">
                <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                    <b>Special Products</b>
                    <span class="dbs-accordion-item__arrow">
                        <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                            <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                            <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                        </svg>
                    </span>
                </button>
                <div class="dbs-accordion-item__content">
                    <div class="block-teaser--full-overlay
                        block-teaser--row
                        ">
                        <picture class="block-teaser__media">
                            <source media="(max-width: 394px)" srcset="https://dbschenker.com/resource/image/639408/16:9/0/200/e1efbff19debb84d0da56792175cfb74/EP/-specialp-products-category-image--global-en-.jpg">
                            <source media="(max-width: 507px)" srcset="https://dbschenker.com/resource/image/639408/21:9/0/200/309b0d81e36c3946b5dca9437bfa49c2/Ob/-specialp-products-category-image--global-en-.jpg">
                            <source media="(max-width: 590px)" srcset="https://dbschenker.com/resource/image/639408/11:4/0/200/6b4860f80832eefe8098fb3669d3d748/vP/-specialp-products-category-image--global-en-.jpg">
                            <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/639408/4:1/0/200/6cc1eb1f743a44b1fe2682fbd523322d/BI/-specialp-products-category-image--global-en-.jpg">
                            <source media="(max-width: 934px)" srcset="https://dbschenker.com/resource/image/639408/21:9/0/400/309b0d81e36c3946b5dca9437bfa49c2/dR/-specialp-products-category-image--global-en-.jpg">
                            <source media="(max-width: 1023px)" srcset="https://dbschenker.com/resource/image/639408/11:4/0/400/6b4860f80832eefe8098fb3669d3d748/gt/-specialp-products-category-image--global-en-.jpg">
                            <source media="(max-width: 1050px)" srcset="https://dbschenker.com/resource/image/639408/4:3/0/525/fa72301086b4f356bb07c3bf1207b383/wk/-specialp-products-category-image--global-en-.jpg">
                            <source media="(min-width: 1051px)" srcset="https://dbschenker.com/resource/image/639408/16:9/0/525/e1efbff19debb84d0da56792175cfb74/sZ/-specialp-products-category-image--global-en-.jpg">
                            <img src="" alt="Special Products Truck Crane" class="">
                        </picture>
                        <div class="block-teaser__text theme-light block-teaser__text--with-links align-left">
                            <h2 class="h3 block-teaser__title">Special Products</h2>
                            <div class="grid grid--wide">
                                <p class="grid__item portable--one-half" data-truncate-lines="8" style="">As businesses become increasingly complex, you need a supply chain partner who can meet the individual needs of your business. No matter your industry, DB Schenker offers a specific portfolio of customized solutions that meet your specific requirements.</p>
                                <div class="grid__item portable--one-half">
                                    <ul class="list ">
                                        <li class="list__item">
                                            <a href="/global/products/special-products/value-added-logistics-services" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Sensor Technology (Smartbox)</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/special-products/db-schenker-on-demand-production" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>DB Schenker On-Demand Production</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/special-products/global-projects" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Global Projects &amp; Industry Solutions</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/special-products/trade-fair-logistics" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Fairs &amp; Exhibitions</span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/special-products/sport-event-logistics" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Sport and Event Logistics </span>
                                            </a>
                                        </li>
                                        <li class="list__item">
                                            <a href="/global/products/special-products/hotel-logistics" class="lk lk--default lk-effect " tabindex="">
                                                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                                </svg>
                                                <span>Hotel Logistics</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <hr class="hr">
    <div class="js-dbs-lightbox dbs-lightbox">
        <button class="btn dbs-lightbox__close js-dbs-close-lightbox">
            <svg class="icon icon--close icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10.7 10l7.6-7.6c.2-.2.2-.5 0-.7s-.5-.2-.7 0L10 9.3 2.4 1.6c-.2-.2-.5-.2-.7 0s-.2.5 0 .7L9.3 10l-7.6 7.6c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1l7.6-7.6 7.6 7.6c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7L10.7 10z"></path>
            </svg>
        </button>
    </div>
    <div class="property-animation-test"></div>

</template>

<script>
export default {


}
</script>

<style>

</style>
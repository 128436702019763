<template>


    <hr class="visuallyhidden" id="main-content-delimiter">
    <div class="js-slider stage-video block-stage block-stage--regular stage-video--disable-nav" data-slider-stage="" data-slider-stage-disable="stage-video--disable-nav" data-slider-video="" data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing">
        <div class="js-slider-items block-stage__items stage-video__items slick-initialized slick-slider">
            <div aria-live="polite" class="slick-list draggable">
                <div class="slick-track" style="opacity: 1; width: 1903px; transform: translate3d(0px, 0px, 0px);" role="listbox">
                    <div class="block-stage__item slick-slide slick-current slick-active" style="width: 1903px;" tabindex="-1" role="option" aria-describedby="slick-slide00" data-slick-index="0" aria-hidden="false">
                        <picture class="block-stage__picture block-stage__picture--only">
                            <source media="(max-width: 360px)" srcset="https://dbschenker.com/resource/image/598792/4:3/360/0/d306110c150c59defb16919970ec59fb/Dy/db-schenker-airfreight-plane-sideview.jpg">
                            <source media="(max-width: 479px)" srcset="https://dbschenker.com/resource/image/598792/4:3/479/0/d306110c150c59defb16919970ec59fb/ux/db-schenker-airfreight-plane-sideview.jpg">
                            <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/598792/4:3/767/0/d306110c150c59defb16919970ec59fb/tl/db-schenker-airfreight-plane-sideview.jpg">
                            <source media="(max-width: 1024px)" srcset="https://dbschenker.com/resource/image/598792/4:1/1024/0/cf78b5c6632cb06f36e1836237e3c0e9/Ah/db-schenker-airfreight-plane-sideview.jpg">
                            <source media="(min-width: 1025px)" srcset="https://dbschenker.com/resource/image/598792/4:1/1920/0/cf78b5c6632cb06f36e1836237e3c0e9/Vi/db-schenker-airfreight-plane-sideview.jpg">
                            <img src="" alt="DB Schenker AirFreight Plane Sideview" class="block-stage__img">
                        </picture>
                        <div class="block-stage__content wrapper wrapper--gutter"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper wrapper--gutter">
        <div class="grid">
            <div class="grid__item landscape--two-thirds">
                <div class="wrapper">
                    <div class="wrapper wrapper--gutter-right">
                        <div class="text-block-intro-page">
                            <h1 class="h2">Air Freight That Delivers On Time, Every Time</h1>
                            <div>
                                <p>In today’s “need it yesterday” business world, air freight plays a critical role in your company’s global supply chain. Every year, more than $6 trillion in goods are carried by air — or $18.6 billion worth of goods daily.</p>
                                <p>Your customers have set the bar high when it comes to service, responsiveness, and quick fulfillment. Whether you need to ship temperature-sensitive immunizations to patients quickly, get your high-dollar electronics to retailers for a big promotion, or send a critical part to a stalled assembly line within a few hours, <strong>air freight is the solution</strong>. DB Schenker provides maximum levels of flexibility and optimization for all your air cargo needs.</p>
                                <p>The fastest way to get goods to their destinations, air freight helps you reach these and other time-sensitive projects by providing fast delivery of cargo while minimizing unexpected delays or obstacles.</p>
                                <p><strong>Enjoy the Benefits of DB Schenker Air Freight</strong></p>
                                <ul class="rte-unordered-list">
                                    <li>Worldwide network across major global hubs on all continents&nbsp;</li>
                                    <li>Fast and convenient online booking with&nbsp;<a href="https://www.dbschenker.com/global/connect/connect-4-0" target="_blank" rel="noopener" class="lk lk--default lk--effect-within-article">connect 4.0</a></li>
                                    <li>Capacities on a wide variety of routes including defined scheduling and lead time transparency&nbsp;</li>
                                    <li>Partnership with first class carriers as well as an&nbsp;<a href="https://www.dbschenker.com/global/products/air-freight/global-flight-network" target="_blank" rel="noopener" class="lk lk--default lk--effect-within-article">own controlled carrier network</a></li>
                                    <li>Online tracking combined with visibility solutions, for example with&nbsp;<a href="https://www.dbschenker.com/global/products/air-freight/airtrack" target="_blank" rel="noopener" class="lk lk--default lk--effect-within-article">AirTrack</a></li>
                                </ul>
                                <p><br></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {


}
</script>

<style>

</style>
<template>
<TestTwo />
</template>


<script>
import TestOne from '@/components/TestOne.vue'
import TestTwo from '@/components/TestTwo.vue'
import TestThree from '@/components/TestThree.vue'
import TestFour from '@/components/TestFour.vue'

export default {
  name: 'Test',
  
  components: {
    TestOne, TestTwo, TestThree, TestFour,
  },

  data: () => ({
    //
  }),

};
</script>
<template>

<section class="elq-newsletter-wrapper section-wrapper">
	<div class="wrapper elq-newsletter">
		<header class="elq-newsletter-header">
			<picture class="elq-newsletter-header__picture">
				<source srcset="/resource/image/655362/16:9/320/0/620011390dc60a63b5c810dee38eb475/nz/newsletter.jpg" media="(max-width: 360px)">
				<source srcset="/resource/image/655362/16:9/439/0/620011390dc60a63b5c810dee38eb475/uC/newsletter.jpg" media="(max-width: 479px)">
				<source srcset="/resource/image/655362/21:9/727/0/1ce6235aaa9589d76138c322fa01af0c/ii/newsletter.jpg" media="(max-width: 767px)">
				<source srcset="/resource/image/655362/1:1/371/0/33ed2db9cb884d889f9087ba79c7f67/Ub/newsletter.jpg" media="(max-width: 1024px)">
				<source srcset="/resource/image/655362/4:3/431/0/8fa209cd28baa0d55c581e3235fca39f/dx/newsletter.jpg" media="(max-width: 1200px)">
				<source srcset="/resource/image/655362/1:1/474/0/33ed2db9cb884d889f9087ba79c7f67/en/newsletter.jpg" media="(max-width: 1440px)">
				<source srcset="/resource/image/655362/4:3/475/0/8fa209cd28baa0d55c581e3235fca39f/BZ/newsletter.jpg" media="(max-width: 1920px)">
				<source srcset="/resource/image/655362/16:9/730/0/620011390dc60a63b5c810dee38eb475/ys/newsletter.jpg" media="(min-width: 1921px)">
				<img src="" alt="" class="">
			</picture>
			<h2 class="elq-newsletter-header__title elq-newsletter-title"> Never miss any news</h2>
			<p class="elq-newsletter-header__text">
				Join our mailing list and receive the latest news, product and service information as well as special offers and exclusive content about the new age of the logistics industry.
			</p>
		</header>
		<form class="elq-newsletter__form js-form-newsletter" data-error-text=" This field is required">
			<div class="js-newsletter-confirmation elq-newsletter__confirmation" aria-hidden="true">
				<h3 class="elq-newsletter__confirmation-text elq-newsletter-title">Thank you for your request. Your submission was successful.</h3>
				<button class="btn btn--pointer js-newsletter-confirmation-exit-button elq-newsletter__confirmation-text" type="button">Register another e-mail</button>
			</div>
			<input hidden="" value="https://www.dbschenker.com/global/connect/connect-4-0" name="refURL">
			<div class="elq-newsletter__section">
				<select class="elq-newsletter__field elq-newsletter__salutation-field elq-newsletter__dropdown js-form-required-field" name="salutation">
					<option disabled="" selected="" value="">Salutation</option>
					<option class="js-form-unspecified-salutation" value="unspecified">-</option>
					<option value="Ms.">Ms.</option>
					<option value="Mr.">Mr.</option>
				</select>
			</div>
			<div class="elq-newsletter__section">
				<input class="elq-newsletter__field js-form-required-field" placeholder="First Name" name="firstName">
			</div>
			<div class="elq-newsletter__section">
				<input class="elq-newsletter__field js-form-required-field" placeholder="Last Name" name="lastName">
			</div>
			<div class="elq-newsletter__section">
				<input class="elq-newsletter__field js-form-required-field" type="email" placeholder="E-mail" name="email">
			</div>
			<div class="elq-newsletter__locale-selection elq-newsletter__section">
				<div class="elq-newsletter__country-field-wrapper">
					<select class="elq-newsletter__field elq-newsletter__dropdown js-newsletter-country js-form-required-field" name="country">
						<option disabled="" selected="" value=""> Country</option>
						<option value="AR">Argentina</option>
						<option value="AU">Australia</option>
						<option value="AT">Austria</option>
						<option value="BE">Belgium</option>
						<option value="BA">Bosnia and Herzegovina</option>
						<option value="BR">Brazil</option>
						<option value="BG">Bulgaria</option>
						<option value="CA">Canada</option>
						<option value="CL">Chile</option>
						<option value="HR">Croatia</option>
						<option value="CZ">Czechia</option>
						<option value="DK">Denmark</option>
						<option value="EG">Egypt</option>
						<option value="EE">Estonia</option>
						<option value="FI">Finland</option>
						<option value="FR">France</option>
						<option value="DE">Germany</option>
						<option value="GR">Greece</option>
						<option value="GT">Guatemala</option>
						<option value="HU">Hungary</option>
						<option value="ID">Indonesia</option>
						<option value="IT">Italy</option>
						<option value="KE">Kenya</option>
						<option value="LV">Latvia</option>
						<option value="LT">Lithuania</option>
						<option value="LU">Luxembourg</option>
						<option value="MK">Macedonia</option>
						<option value="MX">Mexico</option>
						<option value="NL">Netherlands</option>
						<option value="NO">Norway</option>
						<option value="PA">Panama</option>
						<option value="PE">Peru</option>
						<option value="PL">Poland</option>
						<option value="RO">Romania</option>
						<option value="SA">Saudi Arabia</option>
						<option value="SG">Singapore</option>
						<option value="SK">Slovakia</option>
						<option value="SI">Slovenia</option>
						<option value="ZA">South Africa</option>
						<option value="SE">Sweden</option>
						<option value="UA">Ukraine</option>
						<option value="US">USA</option>
					</select>
				</div>
				<div class="elq-newsletter__language-field-wrapper" data-asd="">
				</div>
				<input class="js-newsletter-locale" type="hidden" name="preferredLocale" value="">
			</div>
			<p class="elq-newsletter__section">
				We are using your personal data to process this request in accordance with the legal requirements and our <a href="/global/meta/privacy-policy">Privacy Policy</a>.
			</p>
			<label class="elq-newsletter__section elq-newsletter__subscribe-checkbox-group" for="elq-subscribe-checkbox">
				<input type="checkbox" id="elq-subscribe-checkbox" class="elq-newsletter__subscribe-checkbox js-form-hidden-checkbox js-form-required-field" value="" name="emailOptin">
				<svg class="icon icon--icon-checkmark icon--svg elq-newsletter__checkbox-svg" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
					<path d="M10.65 3.01l-4.46 7.27-2.47-3.2" stroke-width="1.5" stroke="currentColor" fill="none"></path>
				</svg>
				<p>
					Yes, I would like to receive information about DB Schenker products, services and campaigns on the basis of my personal interests via email. I am aware that email opens will be tracked. I can withdraw my consent any time by clicking on the unsubscribing link in each email. Further information on our data processing in connection with this newsletter can be found in our <a href="/global/meta/privacy-policy">Privacy Policy</a>.
				</p>
			</label>
			<button class="elq-newsletter__submit">
			<span class="js-newsletter-submit-spinner elq-newsletter__spinner">
			<span class="js-newsletter-submit-text"> Sign Up</span>
			</span>
			</button>
		</form>
	</div>
</section>

</template>

<script>
export default {


}
</script>

<style>

</style>